import React, { useState } from 'react';
import { Search, Copy, Check, History, Star, Clock } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const EMOJI_CATEGORIES = [
  {
    name: "Frequently Used",
    icon: <Clock className="w-5 h-5" />,
    emojis: ["😊", "❤️", "👍", "🎉", "✨", "🔥", "💯", "🙌", "👏", "🎈"]
  },
  {
    name: "Smileys & People",
    icon: <Star className="w-5 h-5" />,
    emojis: ["😀", "😃", "😄", "😁", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", "🥰"]
  }
  // Add more categories
];

const EmojiKeyboard: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [copied, setCopied] = useState<string | null>(null);
  const [recentEmojis, setRecentEmojis] = useState<string[]>([]);

  const handleCopy = (emoji: string) => {
    navigator.clipboard.writeText(emoji);
    setCopied(emoji);
    setTimeout(() => setCopied(null), 2000);
    
    // Add to recent emojis
    setRecentEmojis(prev => {
      const newRecent = [emoji, ...prev.filter(e => e !== emoji)].slice(0, 30);
      localStorage.setItem('recentEmojis', JSON.stringify(newRecent));
      return newRecent;
    });
  };

  return (
    <>
      <Helmet>
        <title>📱 Ultimate Emoji Keyboard - Fast Copy & Paste | Free Online Emoji Picker [2024]</title>
        <meta name="description" content="✨ FASTEST emoji keyboard online! Instant copy with one click 📱 Mobile-friendly design 🚀 Smart search 💾 Recent emojis 📋 Auto-copy technology. Perfect for social media!" />
        <link rel="canonical" href="https://emojicollection.net/emoji-keyboard" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Ultimate Emoji Keyboard - Fast Copy & Paste | Free Online Emoji Picker" />
        <meta property="og:description" content="✨ FASTEST emoji keyboard online! Instant copy with one click 📱 Mobile-friendly design 🚀 Smart search 💾 Recent emojis" />
        <meta property="og:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />
        <meta property="og:url" content="https://emojicollection.net/emoji-keyboard" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ultimate Emoji Keyboard - Fast Copy & Paste | Free Online Emoji Picker" />
        <meta name="twitter:description" content="✨ FASTEST emoji keyboard online! Instant copy with one click 📱 Mobile-friendly design 🚀 Smart search" />
        <meta name="twitter:image" content="https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80" />

        {/* Additional SEO */}
        <meta name="keywords" content="emoji keyboard, emoji picker, copy paste emoji, emoji search, online emoji keyboard, emoji collection, mobile emoji keyboard, free emoji keyboard" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Ultimate Emoji Keyboard",
            "applicationCategory": "UtilityApplication",
            "operatingSystem": "Any",
            "description": "Fast and easy-to-use online emoji keyboard with instant copy and paste functionality",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "screenshot": "https://images.unsplash.com/photo-1633613286991-611fe299c4be?auto=format&fit=crop&w=1200&h=630&q=80",
            "featureList": [
              "One-click copy",
              "Smart search",
              "Recent emojis",
              "Mobile-friendly",
              "Auto-copy technology"
            ]
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <div className="text-center mb-8">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Ultimate Emoji Keyboard 📱
              </h1>
              <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                The fastest and most user-friendly emoji keyboard online. One-click copy, smart search, and recent emojis - everything you need for quick emoji access!
              </p>

              <div className="max-w-xl mx-auto mb-8">
                <div className="relative">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search emojis..."
                    className="w-full px-4 py-3 pl-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>

              {/* Recent Emojis */}
              {recentEmojis.length > 0 && (
                <div className="mb-8">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <History className="w-5 h-5 mr-2" />
                    Recent Emojis
                  </h2>
                  <div className="grid grid-cols-8 sm:grid-cols-10 md:grid-cols-12 lg:grid-cols-15 gap-2">
                    {recentEmojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => handleCopy(emoji)}
                        className="relative p-2 text-2xl hover:bg-gray-100 rounded transition-colors"
                      >
                        {emoji}
                        {copied === emoji && (
                          <span className="absolute inset-0 flex items-center justify-center bg-green-500 bg-opacity-90 rounded text-white text-sm">
                            <Check className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Emoji Categories */}
              {EMOJI_CATEGORIES.map((category, index) => (
                <div key={index} className="mb-8">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    {category.icon}
                    <span className="ml-2">{category.name}</span>
                  </h2>
                  <div className="grid grid-cols-8 sm:grid-cols-10 md:grid-cols-12 lg:grid-cols-15 gap-2">
                    {category.emojis.map((emoji, emojiIndex) => (
                      <button
                        key={emojiIndex}
                        onClick={() => handleCopy(emoji)}
                        className="relative p-2 text-2xl hover:bg-gray-100 rounded transition-colors"
                      >
                        {emoji}
                        {copied === emoji && (
                          <span className="absolute inset-0 flex items-center justify-center bg-green-500 bg-opacity-90 rounded text-white text-sm">
                            <Check className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmojiKeyboard;