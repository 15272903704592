import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import AdLayout from './components/AdLayout';
import PrivacyBanner from './components/PrivacyBanner';

// Import pages
import Home from './pages/Home';
import Hearts100 from './pages/Hearts100';
import About from './pages/About';
import Contact from './pages/Contact';
import EmojiKeyboard from './pages/EmojiKeyboard';
// ... other imports

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen flex flex-col">
          <Header />
          <PrivacyBanner />
          <main className="flex-grow">
            <AdLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/hearts-100" element={<Hearts100 />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/emoji-keyboard" element={<EmojiKeyboard />} />
                {/* Add other routes */}
              </Routes>
            </AdLayout>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;