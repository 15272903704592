import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const buttonColors = [
  'bg-indigo-500',
  'bg-violet-500',
  'bg-fuchsia-500',
  'bg-purple-500',
  'bg-blue-500',
  'bg-cyan-500',
  'bg-teal-500',
  'bg-emerald-500'
];

const titleColors = [
  'text-indigo-600',
  'text-violet-600',
  'text-fuchsia-600',
  'text-purple-600',
  'text-blue-600',
  'text-cyan-600',
  'text-teal-600',
  'text-emerald-600'
];

const PopularPosts: React.FC = () => {
  const navigate = useNavigate();
  const [randomColors, setRandomColors] = useState(buttonColors);
  const [randomTitleColors, setRandomTitleColors] = useState(titleColors);

  useEffect(() => {
    setRandomColors([...buttonColors].sort(() => Math.random() - 0.5));
    setRandomTitleColors([...titleColors].sort(() => Math.random() - 0.5));
  }, []);

  const popularContent = [
    {
      path: '/hearts-100',
      emojis: ['❤️', '💖', '💝'],
      title: '100 Hearts Collection',
      description: 'Copy and paste 100 beautiful heart emojis with just one click!',
      tag: 'Most Popular'
    },
    // ... other content items
  ];

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-indigo-600 mb-6">Popular Posts</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {popularContent.map((item, index) => (
          <div
            key={item.path}
            onClick={() => navigate(item.path)}
            className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden transform hover:-translate-y-1 cursor-pointer"
          >
            {/* ... card content */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularPosts;