import { Heart, Star, Gift, Smile, Flower2, Music, Coffee } from 'lucide-react';

export const featuredCategories = [
  {
    title: 'Love & Hearts',
    icon: Heart,
    description: 'Express your love with beautiful heart emojis',
    path: '/hearts-100',
    emoji: '❤️',
    gradient: 'from-pink-500 to-rose-500'
  },
  {
    title: 'Celebrations',
    icon: Star,
    description: 'Perfect for parties and special occasions',
    path: '/party-1000',
    emoji: '🎉',
    gradient: 'from-indigo-500 to-purple-500'
  },
  {
    title: 'Christmas',
    icon: Gift,
    description: 'Spread holiday cheer with festive emojis',
    path: '/christmas-1000',
    emoji: '🎄',
    gradient: 'from-green-500 to-emerald-500'
  },
  {
    title: 'Romance',
    icon: Star,
    description: 'Romantic emojis for special moments',
    path: '/roses-1000',
    emoji: '🌹',
    gradient: 'from-red-500 to-pink-500'
  },
  {
    title: 'Expressions',
    icon: Smile,
    description: 'Show your emotions with expressive emojis',
    path: '/love-emojis',
    emoji: '😊',
    gradient: 'from-amber-500 to-orange-500'
  },
  {
    title: 'Nature',
    icon: Flower2,
    description: 'Beautiful nature and flower emojis',
    path: '/blue-roses-1000',
    emoji: '🌸',
    gradient: 'from-teal-500 to-cyan-500'
  },
  {
    title: 'Celebrations',
    icon: Music,
    description: 'Party and celebration emojis',
    path: '/party-1000',
    emoji: '🎈',
    gradient: 'from-violet-500 to-purple-500'
  },
  {
    title: 'Food & Drink',
    icon: Coffee,
    description: 'Delicious food and drink emojis',
    path: '/food-drink',
    emoji: '🍕',
    gradient: 'from-orange-500 to-red-500'
  }
];